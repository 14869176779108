import React from 'react'
import PropTypes from 'prop-types'
import { error as logError } from 'commons/js/util/logger'

const handleException = (error) => {
    logError(error)
}

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    }

    static getDerivedStateFromError () {
        return {
            hasError: true,
        }
    }

    componentDidCatch (error) {
        this.props.handleException(error)
    }

    render () {
        if (this.state.hasError) {
            return this.props.fallback
        }
        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    handleException: PropTypes.func,
    fallback: PropTypes.node,
    children: PropTypes.node,
}

ErrorBoundary.defaultProps = {
    handleException,
    fallback: null,
}

export default ErrorBoundary
